<template>
  <div class="grid grid-cols-1 md:grid-cols-1 gap-12 items-center justify-between text-left mt-12 pb-32">
    <h1 class="text-3xl text-center">
      The $PIXEL Roadmap
    </h1>
    
    <SpeechBubble
      :img="state.images.eagleRight"
      :show="true"
      :potus="''"
    >
      <p>
        Our quest to make the most valuable NFTs on the Tezos blockchain - A new PIXEL utility token, custom swag and a brand new strategic trading card game with play-for-reward functionality.
      </p>
      <br>
      <p>
        See a summary & timeline below, or see our new <a
          class="text-blue-light"
          href="https://medium.com/@pixelpotus/pixelpotus-whats-next-23a54eaa7ed8"
          target="_blank"
        >medium article</a> for a deep dive.
      </p>
    </SpeechBubble>

    
    <div
      class="nes-container is-rounded is-dark text-xs md:text-base"
    >
      <h1 class="text-3xl text-center">
        Tokenomics
      </h1>
      <div>
        <p class="text-gray-600">
          <ul class="list-disc leading-loose">
            <li>100,000,000 Minted</li>
            <li>1,000,000 (1%) proportionally airdropped to the top 500 PixelPotus players based on a snapshot of their rarity score on November 23rd, 2021 at Midnight UTC.</li>
            <li>15,000,000 (15%) Private sale, 3mo lockup, 5mo slow release</li>
            <li>5,000,000 (5%) Rocket IFO on October 30th, 2021.</li>
            <li>5,500,000 (5.5%) Reserved for initial Quipuswap listing</li>
            <li>11,000,000 (11%) Reserved for marketing & bounty programs</li>
            <li>10,000,000 (10%) Reserved to seed in game rewards</li>
            <li>5,000,000 (5%) Advisors, 3mo lockup, 5mo slow release</li>
            <li>10,000,000 (10%) Team, 3mo lockup, 5mo slow release</li>
            <li>37,500,000 (37.5%) Escrow, unlocked in chunks over time as needed to meet demand from gameplay.</li>
            <li>These numbers are subject to change based on the project evolution.</li>
          </ul>
        </p>
      </div>
    </div>

    <h1 class="text-3xl text-center">
      Timeline
    </h1>
    <Timeline
      :value="events"
      align="alternate" 
      class="customized-timeline"
    >
      <template #marker="slotProps">
        <span
          class="custom-marker p-shadow-2"
        >
          <img
            :src="slotProps.item.icon"
          >
        </span>
      </template>
      <template #content="slotProps">
        <div
          class="nes-container is-rounded text-xs md:text-base"
          style="margin-bottom: 20px !important;"
        >
          <h2 class="text-2xl">
            {{ slotProps.item.title }}
          </h2>
          <p class="text-gray-800 underline">
            {{ slotProps.item.date }}
          </p>
          <div>
            <img
              v-if="slotProps.item.image"
              :src="'demo/images/product/' + slotProps.item.image"
              :alt="slotProps.item.name"
              width="200"
              class="p-shadow-2"
            >
            <p class="text-gray-600">
              {{ slotProps.item.text }}
            </p>
          </div>
        </div>
      </template>
    </Timeline>
    <hr>
    <div
      class="nes-container is-rounded is-dark text-xs md:text-base"
    >
      <h2 class="text-2xl">
        Disclaimer
      </h2>
      <div>
        <p class="text-gray-600">
          While the above roadmap represents the latest direction by the PixelPotus team, we reserve the right to make changes as needed to support the best interests of the project. In addition, as described above, voting by pxlDAO holders may also affect the above. We reserve the right to protect the interests of PixelPotus by observing any and all legislation including the restriction of participation in certain jurisdictions.
        </p>
        Revision Date: 10/13/2021
      </div>
    </div>
  </div>
</template>

<script>

import SpeechBubble from '../components/SpeechBubble'

export default {
  name: 'Claim',
  components: {
    SpeechBubble,
  },
  data () {
    return {
      state: this.$root.$data.state,
      events: [
          { title: 'PIXEL Token Launch / Rocket IDO', date: 'Oct 23 2021', icon: this.$root.$data.state.images.partypotus, color: '#673AB7', text: `PIXEL Token is the utility token of the POTUS ecosystem, and beyond. It will be used to pay for in game assets, perks and services in our upcoming projects.` },
          { title: 'pxlDAO Farms', date: 'Late Oct 2021', icon: this.$root.$data.state.images.trump, color: '#FF9800', text: `pxlDAO will be used for voting on the direction of PixelPotus and all future projects from our team.` },
          { title: 'The PIXEL Shop', date: 'Nov 2021', icon: this.$root.$data.state.images.greatseal, color: '#9C27B0', text: `Shirts, Caps, Mugs, Exclusive NFTs and more with PixelPotus themed artwork. Unique POTUS owners receive a percentage of profit from swag.` },
          { title: 'Airdrops for faithful citizens', date: 'Late Nov 2021', icon: this.$root.$data.state.images.washington, color: '#FF9800', text: `Top ranked PP collectors will be airdropped some $PIXEL proportional to their rarity rank on the leaders page.` },
          { title: 'POTUS Debates TCG - PvP mode', date: 'Nov 2021', icon: this.$root.$data.state.images.kennedy, color: '#607D8B', text: `A turn-based strategy trading card game where players try to exhaust the other player's fortitude while managing their own. Each player brings their own champion (a legendary or unique POTUS selected from their collection) and a 15-card deck of debate tactics.` },
          { title: 'POTUS Debates Campaign mode', date: 'Dec 2021', icon: this.$root.$data.state.images.nixon, color: '#607D8B', text: `In the play-for-rewards POTUS campaign mode, you travel backwards in time debating each president while encountering treasure rooms and popup PIXEL markets. Daily, weekly & monthly quests will be available.` },
          { title: 'POTUS Loans, Ranked battles and Tourneys', date: 'Jan 2022', icon: this.$root.$data.state.images.arthur, color: '#607D8B', text: `Rent-a-POTUS system goes live, along with ranked matchmaking and tournaments.` },
          { title: 'Our next game launches', date: 'Feb 2022', icon: this.$root.$data.state.images.obama, color: '#607D8B', text: `We're working on a brand new collectable NFT game inspired by PixelPotus and using some of the same mechanics but with a much wider appealing theme. Not pixelated. Not POTUSes. We can't wait to show you!` },
      ],
    }
  },
  async mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

::v-deep(.p-timeline-event-content)
::v-deep(.p-timeline-event-opposite) {
    line-height: 1;
}

.p-component {
  font-family: 'Press Start 2P', cursive;
}

@media screen and (max-width: 960px) {
    ::v-deep(.customized-timeline) {
            .p-timeline-event:nth-child(even) {
                flex-direction: row !important;
                
                .p-timeline-event-content {
                    text-align: left !important;
                }
            }

            .p-timeline-event-opposite {
                flex: 0;
            }

            .p-card {
                margin-top: 1rem;
            }
        }
}
</style>