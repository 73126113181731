<template>
  <div class="container mx-auto text-center py-12 px-4 font-px h-screen">
    <burger-menu
      :is-open="menu"
      :toggle-open="() => {menu = !menu}"
      class="absolute top-0 left-0 mt-2 block md:hidden w-full"
    />
    <Header :connect-wallet="connectWallet" />
    <!-- <div class="nes-container is-rounded">
      🎆 🇺🇸 In honor of independence day, the claiming period resets every 6 hours through Monday July 5th. 🇺🇸 🎆
    </div> -->
    <main class="flex-1 overflow-y-auto">
      <router-view v-slot="{ Component }">
        <transition>
          <div>
            <component
              :is="Component"
              :connect-wallet="connectWallet"
            />
          </div>
        </transition>
      </router-view>
      <History
        :is-open="state.history.isOpen"
        :toggle-open="() => {state.history.isOpen = !state.history.isOpen}"
      />
    </main>
  </div>
</template>

<script>
import {
    BeaconEvent,
    defaultEventCallbacks  } from "@airgap/beacon-sdk"
import { BeaconWallet } from '@taquito/beacon-wallet'
import { compose, TezosToolkit } from '@taquito/taquito'
import { tzip12, Tzip12Module } from '@taquito/tzip12'
import { tzip16, Tzip16Module } from '@taquito/tzip16'
import axios from 'axios'
import { find, findIndex } from 'lodash'
import { isMobile } from 'mobile-device-detect'
import moment from 'moment'

import jefferson from "@/assets/common/03Jefferson.png"
import jackson from "@/assets/common/07Jackson.png"
import pierce from "@/assets/common/14Pierce.png"
import grant from "@/assets/common/18Grant.png"
import hayes from "@/assets/common/19Hayes.png"
import arthur from "@/assets/common/21Arthur.png"
import wilson from "@/assets/common/28Wilson.png"
import kennedy from "@/assets/common/35Kennedy.png"
import nixon from "@/assets/common/37Nixon.png"
import clinton from "@/assets/common/42Clinton.png"
import obama from "@/assets/common/44Obama.png"
import trump from "@/assets/common/45Trump.png"
import eagleRight from "@/assets/eagle-right.png"
import greatseal from "@/assets/greatseal-pixel.png"
import washington from "@/assets/logo-head.png"
import partypotus from "@/assets/partypotus.gif"

import BurgerMenu from './components/BurgerMenu.vue'
import Header from './Header'
import History from './views/History'

const store = {
  state: {
    usersCount: 0,
    maxClaimsPerPeriod: 0,
    totalClaims: 0,
    untilNextPeriod: moment(),
    periodStart: new Date().toISOString(),
    periodLen: 300,
    treasureBalance : 0,
    treasureFee: 20,
    treasurePayoutPercent: 25,
    treasurePayouts: [],
    treasureLoading : false,
    contract: process.env.VUE_APP_PP_MAIN,
    debug: false, //process.env.VUE_APP_DEBUG,
    isMobile: isMobile,
    tezos: null,
    ppMain: null,
    ppMarket: null,
    ppAuction: null,
    ppTreasury: null,
    userAddress: null,
    userAlias: null,
    balance: 0,
    wallet: null,
    loading: false,
    allTokens: [],
    stats: null,
    statsTimestamp: null,
    myStats: null,
    myTokens: [],
    myTokensLoaded: false,
    myTokensLoading: true,
    myTokensTimestamp: null,
    sortBy: 'tokenId',
    sortDir: true,
    refreshTimeout: 15000,
    seriesMeta: null,
    tradingFee: 25/1000,
    storageLimit: process.env.VUE_APP_STORAGE_LIMIT,
    aliases: [],
    history: {
      items: [],
      limit: 25,
      offset: 0,
      isOpen: false,
    },
    images: {
      eagleRight, 
      grant,
      pierce,
      jackson,
      trump,
      jefferson,
      wilson,
      greatseal,
      kennedy,
      clinton,
      nixon,
      obama,
      arthur,
      hayes,
      partypotus,
      washington,
    },
    gifs: [
      'https://media.giphy.com/media/uR6JMOFCtLmXkcKOkG/giphy.gif',
      'https://media.giphy.com/media/3o7qDSOvfaCO9b3MlO/giphy.gif',
      'https://media.giphy.com/media/5xaOcLISUrBchel7P2w/giphy.gif',
      'https://media.giphy.com/media/l0HlzECw20Y4dK20o/giphy.gif',
      'https://media.giphy.com/media/l3q2UHBacvdiTFada/giphy.gif',
      'https://media.giphy.com/media/xUPGcnhop8QD7HHztS/giphy.gif',
      'https://media.giphy.com/media/ZWQoadIl0Dpks/giphy.gif',
      'https://media.giphy.com/media/HyuDpwNGHporNEQlK8/giphy.gif',
      'https://media.giphy.com/media/BMXOmkI9ocLntJs5XJ/giphy.gif',
      'https://media.giphy.com/media/lT4N7JiPGATIhVwR91/giphy.gif',
      'https://media.giphy.com/media/fP66c1ZY6JNaanDDKh/giphy.gif',
      'https://media.giphy.com/media/3oFzm10eM9MbffB8Kk/giphy.gif',
      'https://media.giphy.com/media/I0SZc5Ok4FNQI/giphy.gif',
      'https://media.giphy.com/media/6w4k5LkiIcsko/giphy.gif',
      'https://media.giphy.com/media/xTiTnpxf2eJs1ah4ac/giphy.gif',
      'https://media.giphy.com/media/7JI6mrDzbBHJcROiCZ/giphy.gif',
      'https://media.giphy.com/media/6BXy9tYDuxUru/giphy.gif',
      'https://media.giphy.com/media/1TSUKOv4k56aIryKAP/giphy.gif',
      'https://media.giphy.com/media/tkQ8MFJYh0hyM/giphy.gif',
      'https://media.giphy.com/media/4JqMQ4Ei49pN6/giphy.gif',
      'https://media.giphy.com/media/QW8Ar7eDciGBi/giphy.gif',
      'https://media.giphy.com/media/ygue8K7NUPIWiFDoeu/giphy.gif',
      'https://media.giphy.com/media/2QEpmEqRyncDGF75Ck/giphy.gif',
      'https://media.giphy.com/media/raGXADfvPB67R4NjkE/giphy.gif',
      'https://media.giphy.com/media/3daKRVqDKvfK2JyrKl/giphy.gif',
      'https://media.giphy.com/media/pHXeZqje25rVlFkIsC/giphy.gif',
      'https://media.giphy.com/media/oy3mWIOFPTu1VIPiD5/giphy.gif',
      'https://media.giphy.com/media/fZYpwfWWg9F9egQhdS/giphy.gif',
      'https://media.giphy.com/media/jbhzksjOIFWjPY7ZOW/giphy.gif',
      'https://media.giphy.com/media/eKsd2RNOX9m5t0ocfj/giphy.gif',
      'https://media.giphy.com/media/1xkbNpMe3JE1XilDMN/giphy.gif',
      'https://media.giphy.com/media/l4q8c9XJzuMPomcTe/giphy.gif',
      'https://media.giphy.com/media/62aJnbbjvkW9ZXU669/giphy.gif',
      'https://media.giphy.com/media/tEIlEVkgghDMJQ5MyR/giphy.gif',
      'https://media.giphy.com/media/31R8mUy9mGkE6Bn8vl/giphy.gif',
      'https://media.giphy.com/media/Sd8uqMJqpGpP2/giphy.gif',
      'https://media.giphy.com/media/xT39DfLQ4ZauMUYzKM/giphy.gif',
      'https://media.giphy.com/media/UX4Wx38jJjbrZ2GKDt/giphy.gif',
      'https://media.giphy.com/media/3ohs83uxKupZIGAK2c/giphy.gif',
      'https://media.giphy.com/media/3F4T2OKB8ZXYBLVztQ/giphy.gif',
      'https://media.giphy.com/media/1lxVhK3EvVQP0POL72/giphy.gif',
      'https://media.giphy.com/media/SWV4S6i79pygM/giphy.gif',
    ],
    formattedAddr (address) {
      return 'ꜩ' + address.substring(0, 5) + '..' + address.substring(address.length - 5)
    },
    convertFromMutez: (amt, fixed = 3) => {
      return (amt/1000000).toFixed(fixed)
    },
    convertToMutez: (amt) => {
      return amt * 1000000
    },
    async getTreasure() {
      this.treasureLoading = true
      try {
        const resp = await axios({
            url:'/api/treasury', 
          })
        this.treasureBalance = resp.data.treasury.balance
        this.treasureFee = resp.data.treasury.fee
        this.treasurePayoutPercent = resp.data.treasury.payoutPercent
        this.treasurePayouts = resp.data.treasury.payouts.sort((a,b) => {
          if (moment(a.timestamp).isBefore(b.timestamp)) {
            return -1
          }
          return 1
        }).map(p => {
          p.timestamp = moment(p.timestamp).format('ll')
          p.token = find(this.state.allTokens, (tok) => {
            return tok.tokenId === p.tokenId
          })
          const alias = find(this.state.aliases, (a) => {return a.address === p.address})
          if(alias) {
            p.alias = alias.alias
          }
          return p
        })

        this.treasureLoading = false
      } catch (error) {
        this.treasureLoading = false
        // await new Promise((resolve) => setTimeout(resolve, 1000))
        // this.$router.go(0)
      }
    },
    async getClaimStorage () {
      const claimResp = await axios({
        url:'/api/claimMeta', 
      })

      this.periodStart = claimResp.data.meta.periodStart
      this.periodLen = claimResp.data.meta.periodLen
      this.maxClaimsPerPeriod = claimResp.data.meta.maxClaimsPerPeriod
      this.untilNextPeriod = moment(this.periodStart).add(this.periodLen, 'seconds')
      const isNextPeriod = moment(this.periodStart).add(this.periodLen, 'seconds').isBefore(moment())

      if (!isNextPeriod) {
        this.totalClaims = claimResp.data.meta.totalClaims
      } else {
        this.totalClaims = 0
        this.untilNextPeriod = moment().add(this.periodLen, 'seconds')
      }   
    },
    async getUsersCount() {
        const resp = await axios({
            url:'/api/getUsersCount', 
          })
        this.usersCount = resp.data.myStats.count
    },
    getRarity: (rarity) => {
      switch (rarity) {
        case 1000:
          return 'common'
        case 2000:
          return 'uncommon'
        case 3000:
          return 'rare'
        case 4000:
          return 'epic'
        case 5000:
          return 'legendary'
        case 6000:
          return 'unique'
      }
    },
    sortTokens (sortBy, ignore = false) {
      if (this.sortBy == sortBy && !ignore) {
        this.sortDir = !this.sortDir
      }
      this.sortBy = sortBy
      
      this.myTokens.sort((a, b) => {
        switch (sortBy) {
          case 'name':
            if (this.sortDir)
              return (''+a.metadata.name).localeCompare(b.metadata.name)
            return (''+b.metadata.name).localeCompare(a.metadata.name)
          case 'rarity':
            if (this.sortDir)
              return a.tokenId - b.tokenId
            return b.tokenId - a.tokenId
          case 'balance':
            if (this.sortDir)
              return a.balance - b.balance
            return b.balance - a.balance
          default:
            if (this.sortDir)
              return (a.tokenId % 1000) - (b.tokenId % 1000)
            return (b.tokenId % 1000) - (a.tokenId % 1000)
        }
      })
      
    },
    log (...msg) {
      if (this.debug) {
        console.log(...msg)
      }
    },
    async disconnect() {
      await this.wallet.client.clearActiveAccount()
      this.userAddress = null
      this.myTokens = []
      this.myTokensLoaded = false
      localStorage.removeItem('myTokens')
      this.userAlias = null
      localStorage.removeItem('myAlias')
    },
    async loadBalance() {
      const bal = await this.tezos.tz.getBalance(this.userAddress)
      this.balance = bal.toNumber()
    },
    async loadStats (force = false) {
      let lastLoad = moment()
      const ls = localStorage.getItem('stats')
      if (ls) {
        const parsedStats = JSON.parse(ls)
        this.stats = parsedStats.stats
        lastLoad = moment(parsedStats.timestamp)
        this.statsTimestamp = lastLoad
      } 
      if (this.stats === null || lastLoad.isBefore(moment().subtract(1, 'hour')) || force) {
        const statsResp = await axios({
            url:'/api/statsv2', 
          })
        this.stats = statsResp.data.stats
        this.stats.series = this.stats.series.map(s => {
          s.levels = s.levels.map(l => {
            const tok = find(this.allTokens, (tok) => {
              return tok.tokenId === l.tokenId
            })
            if (tok) {
              l.high = tok.high ? tok.high : l.high
              l.low = tok.low ? tok.low : l.low
              l.last = tok.last ? tok.last : l.last
              l.trades = tok.trades ? tok.trades : l.trades
              l.barters = tok.barters ? tok.barters : l.barters
              l.sales = tok.sales ? tok.sales : l.sales
            }
            return l
          })
          return s
        }) 
        localStorage.setItem('stats', JSON.stringify({ stats: this.stats, timestamp: new Date().toISOString() }))
        this.statsTimestamp = moment()
      }
    },
    async loadAllTokens (force = false) {
      const allTokens = localStorage.getItem('allTokens')
      if (allTokens) { 
        this.allTokens = JSON.parse(allTokens).tokens
      } 
      if (force) {
        const tokResp = await axios({
            url:'/api/allTokens', 
          })
        this.allTokens = tokResp.data.tokens.sort((a, b) => {
          const res = (''+a.metadata.shortName).localeCompare(b.metadata.shortName)
          const res2 = (''+a.metadata.name).localeCompare(b.metadata.name)
          return res === 0 ? res2 === 0 ? (a.tokenId) - (b.tokenId) : res2 : res
        })
        localStorage.setItem('allTokens', JSON.stringify({ tokens: this.allTokens }))
      }
    },
    async loadMyTokens (force = false, skipCache = false) {
      const myTokens = localStorage.getItem('myTokens')
      if (myTokens && !skipCache) {
        const mt = JSON.parse(myTokens)
        if (mt.timestamp && moment(mt.timestamp).isAfter(moment().subtract(30, 'minutes'))) {
          this.myTokens.length = 0
          this.myTokens.push(...mt.tokens)
          this.myTokensLoaded = true
          this.myTokensTimestamp = moment(mt.timestamp)
        }
      }

      if (!this.userAddress) {
        return
      }

      this.loadBalance()

      if (!this.myTokensLoaded || force) {
        this.myTokensLoading = true
        const tokResp = await axios({
          method: 'post',
          url:'/api/myTokens', 
          data: {
            allTokens: this.allTokens,
            userAddress: this.userAddress,
          },
        })
        
        tokResp.data.tokens.forEach((tok) => {
          const idx = findIndex(this.myTokens, mt => mt.tokenId === tok.tokenId)
          if (idx > -1) {
            this.myTokens[idx].balance = tok.balance
          } else {
            this.myTokens.push({ ...tok, upgrading: false })
          }
        })

        for( var i = 0; i < this.myTokens.length; i++){ 
          const idx = findIndex(tokResp.data.tokens, tok2 => { return tok2.tokenId === this.myTokens[i].tokenId})
          if ( idx === -1) {
            this.myTokens.splice(i, 1)
            i--
          }
        }
        this.sortTokens(this.sortBy, true)
        this.myTokensLoaded = true
        this.myTokensTimestamp = moment()
        this.myTokensLoading = false

        localStorage.setItem('myTokens', JSON.stringify({ tokens: this.myTokens.map(t => {
          delete t.upgrading
          return t
        }), timestamp: this.myTokensTimestamp.toISOString() }))
      }
    },
    async loadMyAlias () {
      const myAlias = localStorage.getItem('myAlias')
      if (myAlias) {
        this.userAlias = (JSON.parse(myAlias)).alias
      }

      const alias = find(this.aliases, (a) => {return a.address === this.userAddress})
      if(alias) {
        this.userAlias = alias.alias
        localStorage.setItem('myAlias', JSON.stringify({ alias: this.userAlias }))
      }
    },
  },
}

export default {
  name: 'App',
  components: {
    BurgerMenu,
    Header,
    History,
  },
  data () {
    return {
      state: store.state,
      menu: false,
    }
  },
  async mounted() {
    try {
      this.state.log(`Connecting to tezos at ${process.env.VUE_APP_TEZOS_RPC}`)
      this.state.tezos = new TezosToolkit(process.env.VUE_APP_TEZOS_RPC)
      this.state.tezos.addExtension(new Tzip16Module())
      this.state.tezos.addExtension(new Tzip12Module())

      const options = {
        name: 'PixelPotus',
        iconUrl: 'https://www.pixelpotus.com/logo-white.png',
        preferredNetwork: process.env.VUE_APP_TEZOS_NETWORK,
        eventHandlers: {
          [BeaconEvent.PAIR_INIT]: {
            handler: defaultEventCallbacks.PAIR_INIT,
          },
          [BeaconEvent.PAIR_SUCCESS]: {
            handler: defaultEventCallbacks.PAIR_SUCCESS,
          },
        },
      }
      this.state.wallet = new BeaconWallet(options)
      const activeAccount = await this.state.wallet.client.getActiveAccount()
      this.state.userAddress = activeAccount ? await this.state.wallet.getPKH() : null

      await this.state.loadAllTokens(true)

      this.getSeriesMeta()
      await this.getAliases()
      this.state.loadStats()

      if (this.state.userAddress) {
        this.state.tezos.setProvider({ wallet: this.state.wallet })
        this.state.ppMain = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_MAIN, compose(tzip16, tzip12))
        this.state.ppMarket = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_MARKET, tzip16)
        this.state.ppAuction = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_AUCTION, tzip16)
        this.state.ppTreasury = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_TREASURY, tzip16)
        this.state.loadMyTokens(true)
        this.state.loadMyAlias()
      }
    } catch(e) {
      console.error('Unable to init taquito', e)
      await new Promise((resolve) => setTimeout(resolve, 15000))
      this.$router.go(0)
    }
  },
  methods: {
    async connectWallet () {
      try{
        await this.state.wallet.requestPermissions({
          network: {
            type: process.env.VUE_APP_TEZOS_NETWORK,
          },
        })
        this.state.userAddress = await this.state.wallet.getPKH()
        this.state.tezos.setProvider({ wallet: this.state.wallet })

        this.state.loadMyAlias()

        this.state.ppMain = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_MAIN, compose(tzip16, tzip12))
        this.state.ppMarket = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_MARKET, tzip16)
        this.state.ppAuction = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_AUCTION, tzip16)
        this.state.ppTreasury = await this.state.tezos.wallet.at(process.env.VUE_APP_PP_TREASURY, tzip16)
        this.state.loadMyTokens(true)

        await axios({
          method: 'post',
          url:'/api/sendActivity', 
          data: {
            content: `${this.state.formattedAddr(this.state.userAddress)} connected their wallet and became a constituent! :flag_us:`,
          },
        })
      } catch(e) {
        console.error('Unable to connect wallet', e)
      }
    },
    async getSeriesMeta () {
      const resp = await axios({
          url:'/api/seriesMeta', 
        })
      this.state.seriesMeta = resp.data.meta
      this.state.log('seriesMeta', this.state.seriesMeta)
    },
    async getAliases () {
      const resp = await axios('/api/aliases')
      this.state.aliases = resp.data.aliases
      this.state.log('aliases', this.state.aliases)
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'robots',
          content: process.env.VUE_APP_ENV === 'production' ? 'index, follow' : 'noindex, nofollow',
        },
      ],
    }
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.speechbg {
  background-size: 100% 100%;
  background-image: url('assets/tall-speech-bub.png');
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.speechbg-rev {
  background-image: url('assets/tall-speech-bub-right.png');
  background-size: 100% 100%;
  padding-right: 2.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@screen sm {
  .speechbg {
    background-size: 100% 100%;
    background-image: url('assets/tall-speech-bub.png');
    padding-left: 3rem;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .speechbg-rev {
    background-image: url('assets/tall-speech-bub-right.png');
    background-size: 100% 100%;
    padding-right: 3rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
@screen md {
  .speechbg {
    background-size: 100% 100%;
    background-image: url('assets/bubble.png');
    padding-left: 8rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .speechbg-rev {
    background-image: url('assets/bubble-right.png');
    background-size: 100% 100%;
    padding-right: 8rem;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  } 
}

.multiselect, .multiselect__input, .multiselect__single {
    font-size: 12px;
  }
  .multiselect__tag, .multiselect__option--highlight {
    background: #006bb3;
  }
  .multiselect__option--selected.multiselect__option--highlight {
    background: #006bb3;
  }
  .multiselect__tag-icon:after {
    color: rgb(194, 194, 194);
    /* background: #006bb3; */
  }

  .slider-blue {
    --slider-connect-bg: #006bb3;
    --slider-tooltip-bg: #006bb3;
    --slider-handle-ring-color: #3B82F630;
  }
</style>