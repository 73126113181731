import { createRouter, createWebHistory } from 'vue-router'

import About from '../views/About.vue'
import Claim from '../views/Claim.vue'
import GetStarted from '../views/GetStarted.vue'
import Leaders from '../views/Leaders.vue'
import Market from '../views/Market.vue'
import MyCollection from '../views/MyCollection.vue'
import Packs from '../views/Packs.vue'
import Potus from '../views/Potus.vue'
import Roadmap from '../views/Roadmap.vue'
import Stats from '../views/Stats.vue'
import Treasury from '../views/Treasury.vue'
import ViewAuction from '../views/ViewAuction.vue'
import ViewCollection from '../views/ViewCollection.vue'
import ViewTrade from '../views/ViewTrade.vue'

const titleTemplate = `PixelPotus |`
const defaultImage = 'https://cloudflare-ipfs.com/ipfs/QmSRx1BhMdhfhTVfNHMQPtnnudKjUJnNfpjjRUeWAAn1Bi'

const makeRoute = (path, name, component, image=defaultImage) => {
  const strippedPath = path.substring(1)
  return {
    path: path,
    name: name,
    component: component,
    meta: {
      title: `${titleTemplate} ${name}`,
      metaTags: [
        {
          name: 'description',
          content: 'PixelPotus is a digital collectable competition built on the Tezos blockchain. There are 46 presidents with 6 different rarities for each, with custom artwork for each president at each level.',
        },
        {
          property: 'og:description',
          content: 'PixelPotus is a digital collectable competition built on the Tezos blockchain. There are 46 presidents with 6 different rarities for each, with custom artwork for each president at each level.',
          id: 'ogDesc',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${process.env.BASE_URL}${strippedPath}`,
          id: 'ogUrl',
        },
        {
          property: 'og:image',
          content: `${image}`,
          id: 'ogImg',
        },
        {
          property: 'og:title',
          content: `${titleTemplate} ${name}`,
        },
      ],
    },
  }
}

const routes = [
  makeRoute('/', 'Get Started', GetStarted),
  makeRoute('/about', 'About', About),
  makeRoute('/roadmap', 'Roadmap', Roadmap),
  makeRoute('/me', 'My Collection', MyCollection),
  makeRoute('/market', 'Market', Market),
  makeRoute('/claim', 'Claim-a-POTUS', Claim),
  makeRoute('/packs', 'Patriot Packs', Packs),
  makeRoute('/stats', 'Stats', Stats),
  makeRoute('/treasury', 'Treasury', Treasury),
  makeRoute('/leaderboard', 'Leaders', Leaders),
  makeRoute('/potus/:potusId', 'Potus', Potus),
  makeRoute('/trades/:owner/:tradeId', 'ViewTrade', ViewTrade),
  makeRoute('/collection/:owner', 'ViewCollection', ViewCollection),
  makeRoute('/auctions/:auctionId', 'ViewAuction', ViewAuction),
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'underline text-gray-900',
  routes,
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
